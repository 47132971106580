<template>
  <div id="loading-bg" v-if="logoLoader">
    <div class="loading-logo">
      <img :src="require('../../public/loader.gif')" alt="Logo" style="width: 120px; height: 120px;" />
    </div>
  </div>
  <div class="auth-wrapper auth-v2" v-else>
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo mt-2 ml-2 align-items-center">
        <b-img src="../assets/images/logo/logo.svg" class="logo-img"></b-img>
        <h2 class="brand-text">ZigTrack</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            src="../assets/images/Image/logo-img.svg"
            alt="Login V2"            
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-5 p-lg-5">
        <b-overlay
          :show="loader"
          rounded
          opacity="0.6"
          spinner-variant="primary"
          class="w-100"
        >
          <b-col sm="8" md="9" lg="12" xl="9" class="px-xl-2 mx-auto">
            <b-card-title class="login-title">
              Welcome to ZigTrack
            </b-card-title>
            <b-card-text class="login-text">
              Log in to your account
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation" v-show="false">
              <b-form class="auth-login-form mt-3" @submit.prevent>
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      type="email"
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- Password -->
                <b-form-group
                  label="Password"
                  label-for="login-password"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-form-input
                      type="password"
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  class="login-btn"
                  block
                  @click="login()"
                  :disabled="loader"
                >
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>

            <!-- divider -->
            <div class="divider my-2" v-show="false">
              <div class="divider-text">or</div>
            </div>

            <!-- social buttons -->
            <div
              class="
                auth-footer-btn
                d-flex
                justify-content-start
                align-items-center
                mt-3
              "
            >
              <button
                type="button"
                class="login-with-google-btn"
                @click="redirectToAuth(false)"
              >
                Sign in with Google
              </button>
            </div>
          </b-col>
        </b-overlay>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "../store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CryptoJS from 'crypto-js';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "admin@Zig01",
      userEmail: "admin@zignuts.com",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loader: false,
      is_google_err: false,
      is_token_err: false,
      // to set logo Loader 
      logoLoader:false,
    };
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  async mounted() {
    // to set login data using auth servies
    if(this.$route.query.social_auth && (this.$route.query.social_auth == 'true') && this.$route.query.id){
      this.logoLoader=true;

      // set redis key in local storage 
      localStorage.setItem("redisKey",CryptoJS.AES.encrypt(this.$route.query.id, this.VUE_APP_SECRET_KEY).toString());
      // store in state 
      this.$store.commit("app/UPDATE_REDIS_KEY", this.$route.query.id); 

      // get token and user info 
      await this.$axios.post("auth/token", { redis_key:this.$route.query.id }).then((response) => {
        // get token and user info 
        let data = response.data.data;

        // set token and user info in store and local storage 
        this.loginResponse(data.token, data.user);
        this.is_token_err = false;
      }).catch((e) => {
        this.logoLoader=false;
        this.is_token_err = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: e.response.data.message,
            icon: "XIcon",
            variant: "danger",
          },
        });
        this.redirectToAuth(e.response.data.message);
      });
    }else{
      this.redirectToAuth();
    }

    if (this.$route.query.type && this.$route.query.type == "acceepted") {
      this.$bvToast.toast("Invitation has been Accepted successfully.", {
        toaster: "b-toaster-top-center",
        solid: true,
        variant: "success",
      });
    } else if (
      this.$route.query.type &&
      this.$route.query.type == "notinvited"
    ) {
      this.$bvToast.toast("User is not invited.", {
        toaster: "b-toaster-top-center",
        solid: true,
        variant: "danger",
      });
    } else if (this.$route.query.type && this.$route.query.type == "notfound") {
      this.$bvToast.toast("Sorry , Data not found", {
        toaster: "b-toaster-top-center",
        solid: true,
        variant: "danger",
      });
    }

    if (this.$route.query.code && !this.is_google_err) {
      this.loader = true;
      this.googleLoginCallback(this.$route.query.code);
    }
    if (localStorage.getItem("authToken")) {
      this.$router.push({ name: "dashboard" });
    }
    // else{
    //   if(this.is_token_err == false) {
    //     this.redirectToAuth(false);
    //   }
    // }
  },
  methods: {
    // redirect to auth servies 
    redirectToAuth(message) {
      if((message) && (this.is_token_err == true)) {
        window.location.href = process.env.VUE_APP_AUTH_SERVICE_URL+"/login?social_auth=false&message="+ message+"&system_code=" + process.env.VUE_APP_CODE;
      } else {
        window.location.href = process.env.VUE_APP_AUTH_SERVICE_URL+"/login?system_code=" + process.env.VUE_APP_CODE;
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },

    login() {
      // this.$store.dispatch("updateUser", "userInfo");

      this.$refs.loginValidation.validate().then((result) => {
        if (result) {
          let input = {
            email: this.userEmail,
            password: this.password,
          };
          this.$axios
            .post("login", input)
            .then((response) => {
              let data = response.data.data;
              this.loginResponse(data.token, data.user);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    },
    /**
     * redirect to google login
     */
    async handleGoogleSignIn() {
      this.loader = true;
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        var authRes = this.$gAuth.GoogleAuth.currentUser
          .get()
          .getAuthResponse();
        if (authRes && authRes.access_token) {
          // console.log('access_token   access_token',authRes.access_token);
          this.googleLoginCallback(authRes.access_token);
        }

        // console.log(
        //   "getAuthResponse",
        //   this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
        // );
      } catch (error) {
        this.loader = false;
        //on fail do something
        console.error(error);
        return null;
      }
    },
    /**
     * redirect to google login callback
     */
    async googleLoginCallback(token) {
      this.loader = true;
      let input = {
        token: token,
      };
      let response = await this.getHTTPPostResponse(
        "callback/google",
        input,
        false
      );
      if (response && response.data) {
        let data = response.data;
        this.loginResponse(data.token, data.user);
      } else {
        this.is_google_err = true;
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.logo-img{width: 40px;}
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 29px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  // background-image: url("../assets/images/Image/googlelogo-2.png");
  background-size: 38px;
  background-repeat: no-repeat;
  background-position: 6px 6px;
  width: 100%;
  font-size: 18px;
  border: 1px solid #000000;
  background-color: #fff;
  color: #000000;

  &:hover {
    // box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #ebebeb;
    transition: 0.3s ease-in-out;
  }

  &:active {
    background-color: #ebebeb;
    color: #0000;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    color: #3479fb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
</style>
