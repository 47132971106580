// We haven't added icon's computed property because it makes this mixin coupled with UI
export const togglePasswordVisibility = {
  data() {
    return {
      passwordFieldType: 'password',
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    withHtml() {
      this.$swal({
        title: "Are you sure ?",
        icon: "info",
        html: "<p>Once you delete you will not recover this record.</p>",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Delete",
        confirmButtonAriaLabel: "Thumbs up, great!",
        cancelButtonAriaLabel: "Thumbs down",
        customClass: {
          confirmButton: "btn confirm",
          cancelButton: "btn cancel ml-1",
        },
        buttonsStyling: false,
      });
    },
  },
}

export const _ = null
